import cn from 'classnames';
import React, { memo } from 'react';

import { useIsMobile, useIsTablet } from '@sravni/react-utils';

import { useHeadingTexts } from '@src/components/PageHeader';
import Toggle from '@src/components/PageHeader/PageHeaderSubheading/Toggle';
import { useBranding } from '@src/reducers/branding';

import style from './style.module.scss';

export const PageHeaderSubHeading: React.FC = memo(() => {
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const { desktop, mobile } = useBranding() || {};
    const { creditCalculator = {} } = (isMobile ? mobile : desktop) || {};
    const { mainTextColor, regionTextColor } = creditCalculator;
    const isDesktop = !(isMobile || isTablet);
    const replacements = useHeadingTexts().subHeadingText;

    const subHeader = React.useMemo(() => replacements, [replacements]);

    const [isOpen, toggleIsOpen] = React.useState(false);

    const clickOnToggle = React.useCallback(() => toggleIsOpen((open) => !open), []);

    const componentStyle = React.useMemo(
        () =>
            ({
                '--branding-text-color': regionTextColor || mainTextColor,
            } as React.CSSProperties),
        [mainTextColor, regionTextColor],
    );

    if (isMobile) {
        return null;
    }

    return (
        <div className={cn(style.wrapper)} style={componentStyle}>
            <div
                className={cn(style.text, {
                    [style.hidden]: !(isOpen || isDesktop),
                    [style.textColor]: regionTextColor || mainTextColor,
                })}
            >
                {subHeader}
            </div>
            <Toggle text="Eщё" isOpen={isOpen} onClick={clickOnToggle} />
        </div>
    );
});

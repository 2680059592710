import add from 'date-fns/add';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Button } from '@sravni/react-design-system/lib/Button';
import { Icon } from '@sravni/react-design-system/lib/Icon';
import { Space } from '@sravni/react-design-system/lib/Space';
import { Plus } from '@sravni/react-icons';
import type { Seo } from '@sravni/types/lib/seo';

import type * as Microcredits from '@src/@types/microcredits';
import { SessionStorageKeys } from '@src/@types/sessionStorage';
import { FullDealOffer } from '@src/components/FullDealOffer';
import { AMOUNT_MIN_VALUE, SRAVNI_LOGO_URL } from '@src/components/PageHeader/SimplePageHeaderFilters/constants';
import { AmountControlV2 } from '@src/components/ui/AmountControlV2';
import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';
import { TermControl } from '@src/components/ui/TermControl';
import { AMOUNT_SETTINGS, FILTERS_NAMES } from '@src/constants/filters';
import {
    TERM_MIN,
    AMOUNT_LABEL,
    AMOUNT_MAX,
    AMOUNT_MIN_IL_VALUE,
    AMOUNT_SLIDER_STEP,
    TERM_LABEL,
    TERM_MAX,
    ONE_LINK,
    FULL_DEAL_ENTRY_POINT_TEXTS,
    AMOUNT_DEFAULT_AB_TEST,
    TERM_DEFAULT_AB_TEST,
    WEB_FULL_DEAL_LINK,
} from '@src/constants/fullDeal';
import {
    sendAmountFilterEvent,
    sendOpenAppFullDealEvent,
    sendOpenWebFullDealEvent,
    sendTermFilterEvent,
} from '@src/helpers/analyticsEvents';
import { getDaysPluralize } from '@src/helpers/strings';
import { useMicrocreditDeals } from '@src/hooks/useMicrocreditDeals';
import { Cookie, CookieKey } from '@src/modules/cookie';
import { TermDropdown } from 'src/components/ui/TermDropdown';

import styles from './styles.module.scss';

const { Heading } = Typography;

type Props = {
    filters: Microcredits.IFilters;
    seo?: Partial<Seo>;
    onChange: (value: any, name: string) => void;
    handleClickSelectionButton(): void;
};

export const FullDealForm: FC<Props> = ({ filters, seo, onChange, handleClickSelectionButton }) => {
    const { asPath } = useRouter();

    const amount = filters[FILTERS_NAMES.AMOUNT] ?? AMOUNT_DEFAULT_AB_TEST;
    const term = filters[FILTERS_NAMES.TERM] ?? TERM_DEFAULT_AB_TEST;

    const {
        initialAmount,
        initialTerm,
        hasForm,
        hasOffer,
        isOfferWaiting,
        toFullDealButtonText,
        hasIssuedMicrocreditDeals,
    } = useMicrocreditDeals({
        initialAmount: amount,
        initialTerm: term,
    });

    const hasOfferOrForm = hasOffer || isOfferWaiting || hasForm;

    const handleOpenWebFullDeal = useCallback(
        (event: React.SyntheticEvent, buttonLabel: string) => {
            event.stopPropagation();

            if (!hasOfferOrForm) {
                const expires = add(new Date(), { days: 1 });

                Cookie.set(CookieKey.amount, amount.toString(), { expires, path: '/' });
                Cookie.set(CookieKey.term, term.toString(), { expires, path: '/' });
            }

            sessionStorage.setItem(SessionStorageKeys.referer, asPath);
            sendOpenWebFullDealEvent(buttonLabel);
            window.location.href = WEB_FULL_DEAL_LINK;
        },
        [amount, term, hasOfferOrForm, asPath],
    );

    const handleOpenMobileApp = (event: React.SyntheticEvent) => {
        event.stopPropagation();

        sendOpenAppFullDealEvent(seo?.heading);
        window.open(ONE_LINK, '_blank');
    };

    const isViewOffersButtonVisible = hasOfferOrForm || (!hasOfferOrForm && !hasIssuedMicrocreditDeals);

    return (
        <div className="h-mb-12">
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    {seo && (
                        <Heading className="h-text-center" level={1}>
                            {seo?.heading}
                        </Heading>
                    )}
                    <div className={styles.input}>
                        <div className={styles.sumField}>
                            <AmountControlV2
                                analyticsEvent={sendAmountFilterEvent}
                                steps={[{ ceilThreshold: AMOUNT_MAX, step: AMOUNT_SLIDER_STEP }]}
                                name={FILTERS_NAMES.AMOUNT}
                                className={styles.amount}
                                label={AMOUNT_LABEL}
                                value={amount}
                                min={AMOUNT_MIN_VALUE}
                                max={AMOUNT_SETTINGS.max}
                                onChange={onChange}
                                postfix="₽"
                            />
                        </div>
                        <div className={styles.sumField}>
                            {(filters[FILTERS_NAMES.AMOUNT] ?? 0) < AMOUNT_MIN_IL_VALUE ? (
                                <TermControl
                                    name={FILTERS_NAMES.TERM}
                                    label={TERM_LABEL}
                                    value={term}
                                    onChange={onChange}
                                    postfix={getDaysPluralize(term)}
                                    min={TERM_MIN}
                                    max={TERM_MAX}
                                    analyticsEvent={sendTermFilterEvent}
                                />
                            ) : (
                                <TermDropdown
                                    analyticsEvent={sendTermFilterEvent}
                                    onChange={onChange}
                                    filters={filters}
                                />
                            )}
                        </div>

                        <Space size={8} direction="vertical">
                            {!hasOfferOrForm && (
                                <>
                                    {hasIssuedMicrocreditDeals && (
                                        <>
                                            <Button
                                                size={52}
                                                variant="primary"
                                                color="gray"
                                                onClick={(event) =>
                                                    handleOpenWebFullDeal(
                                                        event,
                                                        `${FULL_DEAL_ENTRY_POINT_TEXTS.newDealButtonText} (${seo.heading})`,
                                                    )
                                                }
                                            >
                                                <Icon size={20} icon={<Plus />} />
                                                {FULL_DEAL_ENTRY_POINT_TEXTS.newDealButtonText}
                                            </Button>

                                            <Button size={52} color="gray" onClick={handleOpenMobileApp}>
                                                {FULL_DEAL_ENTRY_POINT_TEXTS.mobileAppButtonText}
                                            </Button>
                                        </>
                                    )}

                                    {!hasIssuedMicrocreditDeals && (
                                        <Button
                                            size={52}
                                            variant="primary"
                                            color="gray"
                                            onClick={(event) =>
                                                handleOpenWebFullDeal(event, FULL_DEAL_ENTRY_POINT_TEXTS.pickUpLoan)
                                            }
                                        >
                                            {FULL_DEAL_ENTRY_POINT_TEXTS.pickUpLoan}
                                            <OptimizedPicture
                                                imgClassName={styles.logo}
                                                img={SRAVNI_LOGO_URL}
                                                alt="logo"
                                                width={23}
                                                height={20}
                                            />
                                        </Button>
                                    )}
                                </>
                            )}

                            {isViewOffersButtonVisible && (
                                <Button
                                    variant="secondary"
                                    color="gray"
                                    size={52}
                                    onClick={handleClickSelectionButton}
                                    className={styles.buttonFullWidth}
                                >
                                    Посмотреть предложения
                                </Button>
                            )}
                        </Space>
                    </div>
                </div>
            </div>

            {hasOfferOrForm && (
                <div className={styles.container}>
                    <FullDealOffer
                        amount={initialAmount}
                        term={initialTerm as number}
                        hasOffer={hasOffer}
                        toFullDealButtonText={toFullDealButtonText}
                        hasIssuedMicrocreditDeals={hasIssuedMicrocreditDeals}
                        toFullDealButtonOnClick={handleOpenWebFullDeal}
                    />
                </div>
            )}
        </div>
    );
};

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { IGlobalState } from '@src/reducers';
import { getCreditsMetadata } from '@src/reducers/microcredits';
import { useSeoState } from '@src/reducers/seo';
import { getHeadingReplacements, getSubheadingReplacements } from '@src/utils/meta';

interface IParams {
    defaultHeading?: string;
    defaultSubHeading?: string;
}

export const useHeadingTexts = ({ defaultHeading = 'Займы', defaultSubHeading }: IParams | undefined = {}) => {
    const { seo } = useSeoState();
    const currentLocation = useSelector((state: IGlobalState) => state.locations.currentLocation);
    const meta = useSelector(getCreditsMetadata);

    const headingText = useMemo(() => {
        if (seo?.heading) return seo.heading;
        if (!seo?.headingTemplate) return '';
        return getHeadingReplacements(seo.headingTemplate, seo);
    }, [seo]);

    const subHeadingText = useMemo<string | undefined | null>(() => {
        if (!seo?.subHeading) return '';

        return getSubheadingReplacements({ template: seo.subHeading, meta, location: currentLocation });
    }, [seo, meta, currentLocation]);

    return {
        headingText: headingText || defaultHeading || '',
        subHeadingText: subHeadingText || defaultSubHeading,
    };
};
